import request from '@/utils/request'

// 公告管理列表
export function findAnnouncements(params) {
  return request({
    url: `/announcements`,
    method: 'get',
    params: params
  })
}

// 新增公告
export function createAnnouncement(data) {
  return request({
    url: `/announcements`,
    method: 'post',
    data
  })
}

// 公告详情
export function findAnnouncement(id) {
  return request({
    url: `/announcements/${id}`,
    method: 'get'
  })
}

export function findAnnouncementForm(id) {
  return request({
    url: `/announcements/${id}/form`,
    method: 'get'
  })
}

// 编辑公告
export function updateAnnouncement(id, data) {
  return request({
    url: `/announcements/${id}`,
    method: 'put',
    data
  })
}

// 发布公告
export function publishAnnouncement(id) {
  return request({
    url: `/announcements/${id}/publish`,
    method: 'post'
  })
}

// 取消发布
export function cancelAnnouncement(id) {
  return request({
    url: `/announcements/${id}/cancel`,
    method: 'post'
  })
}

// 更新用户密码
export function deleteAnnouncements(data) {
  return request({
    url: `/announcements/batch`,
    method: 'delete',
    data
  })
}
