<template>
  <div>
    <search-announcement @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
          v-if="isShowMenu('announcements/new')"
        >新增</a-button>

        <a-button
          v-if="isShowMenu('announcements/delete')"
          type="primary"
          @click="batchDelete"
        >删除</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="announcement_title" slot-scope="title, record">
        <a @click="showInfoModal(record.id)">
          <span v-if="truncatedTitle(title) === title">
            {{ title }}
          </span>
          <a-tooltip v-else>
            <template slot="title">
              {{ title }}
            </template>

            {{ truncatedTitle(title) }}
          </a-tooltip>
        </a>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isShowEditMenu(record)">编辑</a>
          <a @click="publish(record.id)" v-if="isShowPublishMenu(record)">发布</a>
          <a @click="cancel(record.id)" v-if="isShowCancelMenu(record)">取消发布</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <new-announcement
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <edit-announcement
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <show-announcement
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />
  </div>
</template>

<script>
import { cancelAnnouncement, deleteAnnouncements, findAnnouncements, publishAnnouncement } from '@/api/announcement'
import Pagination from '@/components/Pagination/index'
import SearchAnnouncement from '@/views/announcements/Search'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'AnnouncementList',
  components: {
    SearchAnnouncement,
    Pagination,
    NewAnnouncement: () => import('@/views/announcements/New'),
    EditAnnouncement: () => import('@/views/announcements/Edit'),
    ShowAnnouncement: () => import('@/views/announcements/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingId: 0, // 正在编辑的记录id
      showingId: 0, // 正在查看详情的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '公告名称',
          width: 400,
          dataIndex: 'title',
          scopedSlots: { customRender: 'announcement_title' }
        },
        {
          title: '创建人',
          width: 150,
          dataIndex: 'creator_name'
        },
        {
          title: '状态',
          width: 100,
          dataIndex: 'status'
        },
        {
          title: '发布时间',
          width: 180,
          dataIndex: 'published_at'
        },
        {
          title: '创建时间',
          width: 180,
          dataIndex: 'created_at'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('announcements/edit')
    },

    isHasPublishPermission() {
      return hasPermission('announcements/publish')
    },

    isHasCancelPermission() {
      return hasPermission('announcements/cancel')
    }
  },
  methods: {
    truncatedTitle(title) {
      return this.$lodash.truncate(title, { length: 30, omission: '...' })
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    isShowEditMenu(record) {
      return record.status_slug === 'unpublished' && this.isHasEditPermission
    },

    isShowPublishMenu(record) {
      return record.status_slug === 'unpublished' && this.isHasPublishPermission
    },

    isShowCancelMenu(record) {
      return record.status_slug === 'published' && this.isHasCancelPermission
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    batchDelete() {
      const vm = this
      if (vm.selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择操作对象',
          content: ''
        })
      } else {
        this.$confirm({
          title: '确定删除吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            deleteAnnouncements({ ids: vm.selectedRowKeys }).then((res) => {
              if (res.code === 0) {
                vm.fetchData()
              }
            })
          }
        })
      }
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAnnouncements(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    // 发布
    publish(id) {
      const vm = this
      this.$confirm({
        title: '确定发布吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          publishAnnouncement(id).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    },

    // 取消发布
    cancel(id) {
      const vm = this
      this.$confirm({
        title: '确定取消发布吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          cancelAnnouncement(id).then((res) => {
            if (res.code === 0) {
              vm.fetchData()
            }
          })
        }
      })
    }
  }
}
</script>
